/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button, Collapse, Modal } from 'antd';
import { FaRegBuilding } from 'react-icons/fa';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import styles from './VersionModal.module.scss';

export default function VersionModal({ showNewVersion, setShowNewVersion }) {
  const modalFooter = (
    <div>
      <Button type="default" onClick={() => setShowNewVersion(false)}>
        Fechar
      </Button>
    </div>
  );

  const updateShowNewVersion = () => {
    try {
      const userData = JSON.parse(localStorage.getItem('conecta__userData') ?? {});

      if (userData?.showNewWebVersion) {
        const userId = userData.id;
        api.put(`/User/UpdateShowNewVersion?versionType=0&show=false&userId=${userId}`);
      }
    } catch (error) {
      Utils.logError(error);
    }
  };

  useEffect(() => {
    updateShowNewVersion();
  }, []);

  return (
    <Modal
      title="Novidades e ajustes"
      open={showNewVersion}
      forceRender
      destroyOnClose={true}
      onCancel={() => setShowNewVersion(false)}
      footer={modalFooter}
      width="50%"
    >
      <div className={styles.versionModal}>
        <Collapse defaultActiveKey={['v4.6.9']} accordion>
          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.6.9"
            key="v4.6.9"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Agendamento
            </h4>

            <ul className={styles.bulletList}>
              <li>
                Adicionado novos campos: &quot;Complemento&quot; e &quot;Produto -
                Complemento&quot;.
              </li>

              <li>
                Ajuste na exibição do Nome do Produto nos cartões de Treinamento, agora exibindo
                &quot;Nome Produto - Complemento&quot;.
              </li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.6.8"
            key="v4.6.8"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Lista de Treinamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>Nova tela para visualizar e manipular Treinamentos.</li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Visualização de Treinamentos em formato de lista, com possibilidade de configurar
                dinamicamente as colunas e exportar os registros filtrados.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Possbilidade de Editar, Confirmar, Reservar sem confirmação, Negar, Remover
                múltiplos treinamentos de uma vez.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Agendamento
            </h4>

            <ul className={styles.bulletList}>
              <li>Ajuste nos modelo de envio de e-mail de Treinamento e Transporte.</li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Adicionado duas novas opções de envio de e-mail no card de treinamento: Confirmação
                Instrutor, Confirmação Motorista.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Adicionado nova regra para impedir o agendamento de Instrutores em Treinamentos que
                não realizam.
              </li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Integração
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Correção na integração dos arquivos: Pedido de Compra, Termo de Aceite e Termo de
                Aceite Assinado. Agora eles são integrados corretamente, indicam Envio, Atualização
                e Remoção nas Atividades do Treinamento e também mudam Status para Reservado e
                Pré-Reservado conforme o Termo de Aceite Assinado é enviado ou removido.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Agendamento
            </h4>

            <ul className={styles.bulletList}>
              <li>
                Ajuste nas permissões de todos uploads de Arquivos de Treinamento, agora
                corretamente permite/impede usuário de realizar o upload e remoção.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.4.2"
            key="v4.4.2"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado novos campos: &quot;Valor Instrutor Auxiliar&quot;, &quot;Número Nota
                Fiscal&quot;, &quot;Emissor Nota Fiscal&quot;, &quot;Valor Nota Fiscal Total&quot;,
                &quot;Valor Nota Fiscal Parcial&quot;
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador de Treinamentos
            </h4>

            <ul className={styles.bulletList}>
              <li>
                Adicionado novas colunas: &quot;VALOR INSTRUTOR AUXILIAR&quot;, &quot;NUMERO NOTA
                FISCAL&quot;, &quot;EMISSOR NOTA FISCAL&quot;, &quot;VALOR NOTA FISCAL TOTAL&quot;,
                &quot;VALOR NOTA FISCAL PARCIAL&quot;.
              </li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Integração
            </h4>
            <ul className={styles.bulletList}>
              <li>Melhoria na sincronia entre Bitrix24 e Portal.</li>
            </ul>

            <ul className={styles.bulletList}>
              <li>Regra para impedir duplicidade de Treinamentos.</li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.3.6"
            key="v4.3.6"
          >
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Agendamento
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Produtos que fazem parte do novo grupo de recurso &quot;Personalizado&quot;, podem
                ser Agendados em qualquer slot, respeitando apenas o Tipo de Treinamento (CT, In
                Company, EAD, Serviço).
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Produtos que fazem parte do novo grupo de recurso &quot;Personalizado&quot;, não
                contabilizam nos totais de alunos.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador de Treinamentos
            </h4>

            <ul className={styles.bulletList}>
              <li>
                Produtos personalizados, inclusive os que fazem parte do novo grupo de recurso
                &quot;Personalizado&quot;, tem a coluna &quot;QTD ALUNOS CONTRATADOS&quot; como 0,
                não contabilizando no total de alunos.
              </li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.3.5"
            key="v4.3.5"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador de Treinamentos
            </h4>

            <ul className={styles.bulletList}>
              <li>Adicionado nova coluna: &quot;CNPJ CLIENTE&quot;.</li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Produtos personalizados não contabilizam mais nos totais de alunos.</li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Corrigido um erro na busca dos Novos Slots que impedia treinamentos retroativos
                agendados em novos slots de serem modificados.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Corrigido um erro que impedia os dados salvos na tela de Configurações de serem
                atualizados no cache.
              </li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v4.3.0"
            key="v4.3.0"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado novo botão na parte superior esquerda do popup de Treinamento, para
                possibilitar o envio de E-mail de Confirmação de Treinamento e de Transporte
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Adicionado novo botão na parte superior direita do popup de Treinamento, para
                possibilitar copiar detalhes importantes do Treinamento
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Configurações
            </h4>
            <ul className={styles.bulletList}>
              <li>Adicionado possibilidade de cadastrar Feriados e Pontos Facultativos</li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador de Treinamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado novo filtro para possibilitar trazer Treinamentos que não foram
                agendados.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Adicionado novas colunas: &quot;POSSUI NF TREINAMENTO?&quot;, &quot;POSSUI NF
                TRANSPORTE?&quot;, &quot;DATA INTEGRAÇÃO&quot;.
              </li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Melhorias de desempenho.</li>
            </ul>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v3.4.27"
            key="v3.4.27"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Agora todos os campos e ações em um card de Treinamento geram histórico, separamos
                em duas abas de Atividades dentro do card, possibilitando ter a mesma visão de antes
                apenas com os dados mais importantes, ou uma visão detalhada com todas ações e
                alterações.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Agora é possível ver todas informações de um Treinamento através da &quot;lista de
                cards&quot; que fica a esquerda da tela, ao realizar uma ação de duplo clique em
                cima de qualquer card, o popup com todos os detalhes do treinamento é aberto em modo
                &quot;apenas visualização&quot;.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Adicionado novo campo &quot;Formulário do Instrutor&quot; dentro do popup de
                Treinamento, possibilitando o upload do formulário do instrutor.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Cadastro de Usuários
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Foram adicionadas travas para impedir erros ao vincular uma empresa a um usuário.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Instrutores
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado uma nova funcionalidade para importar uma planilha em Instrutores,
                possibilitando cadastrar múltiplos instrutores de uma vez só.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador de Treinamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>Adicionado nova coluna &quot;Lista de Presença Final&quot;.</li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Integração
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajustes para atualizar corretamente os dados no Bitrix24 e não disparar tantas vezes
                a integração sem necessidade.
              </li>
            </ul>

            <ul className={styles.bulletList}>
              <li>
                Ajuste para não sobrescrever os dados de um Treinamento quando vários treinamentos
                do mesmo negócio são atualizados em sequência.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Corrigido os filtros de busca de treinamentos, agora busca corretamente uma grande
                quantidade de registros e a opção de &quot;Selecionar Todos&quot; funciona
                corretamente.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Visão Geral
            </h4>
            <ul className={styles.bulletList}>
              <li>Ajuste na busca dos Feriados.</li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v3.2.3"
            key="v3.2.3"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Migração de Orçamento para Negócio
            </h4>
            <ul className={styles.bulletList}>
              <li>
                A partir do dia <strong>03/06/2024</strong>, todos os Treinamentos do Portal estão
                diretamente vinculados ao respectivo Negócio no Bitrix24.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Qualquer filtro antigamente realizado através do Código do Orçamento ou Nome do
                Orçamento, agora são realizados pelo Código do Negócio ou Nome do Negócio.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Todas as atualizações de informações que antes eram realizadas no Orçamento, agora
                são realizadas apenas nos campos de Negócio.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>Agora é possível realizar o envio de múltiplas Listas de Presença.</li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Treinamentos com Produtos Personalizados agora exibem o texto do campo
                &quot;Complemento&quot; preenchido na tela de Treinamentos do Bitrix24, não mais
                exibindo o texto &quot;Produto Personalizado&quot;.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Agora produtos que são do mesmo Grupo podem ser agendados no mesmo Slot, desta forma
                se um produto do grupo NR 23 estiver em um slot, é possível adicionar qualquer outro
                produto do grupo NR 23 no mesmo slot, respeitando as regras de
                Compartilhamento/Encaixe.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Ajuste nas Atividades exibidas dentro do card de Treinamento, agora todas as
                atividades exibirão claramente o usário que realizou a ação e também o avatar do
                mesmo.
              </li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v2.16.1"
            key="v2.16.1"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Reagendamento de Treinamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Mantivemos a antiga opção de Reagendamento dentro da opção de Remover para
                treinamentos que precisam ser ajustados na tela de Treinamentos do Bitrix24 e
                adicionamos uma nova opção mais rápida e prática.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajuste nos campos de caixa de texto, possibilitando a rolagem do texto corretamente.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>
                Adicionado um link direto para o Negócio no Bitrix24 dentro do card de Treinamento.
              </li>
            </ul>
            <ul className={styles.bulletList}>
              <li>Adicionado o campo Ponto de Referência dentro do card de Treinamento.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador
            </h4>
            <ul className={styles.bulletList}>
              <li>Adicionado novos campos no Exportador de Treinamentos.</li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v2.14.9"
            key="v2.14.9"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Melhoria na visualização do tooltip (exibido ao clicar uma vez no card ou no número
                de cards no slot).
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Agenda Centro de Treinamento
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Agora é possível filtrar qual CT está sendo visualizado nos filtros da agenda.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Autenticação
            </h4>
            <ul className={styles.bulletList}>
              <li>Agora é obrigatório a criação de senhas mais fortes.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Cadastro de Instrutores
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Agora os novos instrutores registrados terão um usuário criado automaticamente.
              </li>
            </ul>
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Cadastro de Usuários
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado novo campo no cadastro de usuários, para quando for do cargo CT poder
                vincular quais Centros de Treinamento são visíveis.
              </li>
            </ul>

            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v2.6.13"
            key="v2.6.13"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>Possibilidade de reservar múltiplas datas para os instrutores.</li>
              <li>
                Melhoria na visualização do Resumo, agora os cards são agrupados por slot e podem
                ser abertos diretamente pela tela de resumo.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Visão Geral
            </h4>
            <ul className={styles.bulletList}>
              <li>Novo filtro de Centro de Treinamento.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Exportador
            </h4>
            <ul className={styles.bulletList}>
              <li>Exportação de treinamentos para excel.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Cadastros/Listas: Intrutor e Centro de
              Treinamento.
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Os formulários de cadastro foram ajustados, as abas e campos foram reorganizadas, o
                cadastro de documento foi melhorado e agora é possivel ver quais os documentos
                obrigatórios.
              </li>
              <li>
                Nas listas foi adicionado uma nova coluna para informar se o registro possui todos
                os documentos obrigatórios.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Configurações
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Possibilidade de informar quais documentos são obrigatórios em cada formulário.
              </li>
            </ul>

            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v1.5.2"
            key="v1.5.2"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos e Agenda Centro de
              Treinamento
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajuste no upload de Lista de Presença Inicial e Final, agora é possível realizar o
                upload de qualquer tipo de arquivo, sem bloqueios, necessitando preencher os campos
                Alunos Confirmados e Alunos Treinados manualmente.
              </li>
            </ul>
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos e Agenda Centro de
              Treinamento
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajuste nos links de Pedido de Compra, Termo de Aceite e Termo de Aceite Assinado.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v1.4.10"
            key="v1.4.10"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Agora, na visão da Logística no card de Treinamento, é possível utilizar o botão de
                Salvar, e o botão Confirmar passou a salvar os dados do formulário também.
              </li>
              <li>
                Ajuste no modelo de Lista de Presença, agora só são obrigatórios os campos Nome e
                CPF, e opcional o CNPJ.
              </li>
            </ul>
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Integração
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajustado a atualização dos campos Data do Primeiro Treinamento, Datas de Treinamento
                e Endereço do CT.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>Ajuste na busca dos registros de Resumo e Overview.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Notificações
            </h4>
            <ul className={styles.bulletList}>
              <li>Correção no redirecionamento das Notificações.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Instrutores
            </h4>
            <ul className={styles.bulletList}>
              <li>Correção na deleção de Instrutores.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Implementação de um novo sistema de monitoramento de erros e performance.</li>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v1.2.4"
            key="v1.2.4"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agenda Visão Geral
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado indicador na data, exibindo um alerta e destacando na cor laranja para
                Pontos Facultativos e vermelho para Feriados, ao passar o mouse em cima é possível
                ver o nome do Ponto Facultativo/Feriado.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Melhoria visual no popup de Confirmação de Agendamento, agora é possível confirmar o
                Nome do CT, Nome da Empresa e Data.
              </li>
              <li>
                Liberado novamente a possibilidade de agendar treinamentos retroativos, exibindo
                somente um alerta já que não é recomendado.
              </li>
            </ul>
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Permissões
            </h4>
            <ul className={styles.bulletList}>
              <li>Ajustes nas permissões de acesso.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Ajuste no carregamento das telas.</li>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>

          <Collapse.Panel
            className="configuration-divider"
            orientation="left"
            header="v1.1.1"
            key="v1.1.1"
          >
            <h3>Novidades</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Adicionado ícone <FaRegBuilding /> e ao passar o mouse em cima deste ícone exibe o
                Nome do Cliente.
              </li>
              <li>
                Adicionado opção &quot;Reagendamento&quot; ao remover um treinamento da agenda.
              </li>
              <li>
                Melhoria na visibilidade da barra de rolagem dentro do popup exibido quando têm mais
                de 4 treinamentos compartilhados no mesmo slot.
              </li>
              <li>
                Adicionado um indicador na parte de baixo dos slots que possuem 2 ou mais
                treinamentos compartilhados, exibindo o total de alunos de todos os treinamentos no
                slot.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Cadastro de Centros de Treinamento
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Agora é possível adicionar o Recurso do tipo &quot;Serviço&quot;, permitindo
                escolher quantas colunas e slots estarão disponíveis para aquele CT na tela
                Agendamento.
              </li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Visualização de Campos e Permissões
            </h4>
            <ul className={styles.bulletList}>
              <li>A Visualização de Campos e as Permissões agora são atualizados em tempo real.</li>
            </ul>
            <h3>Correções/Ajustes</h3>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agendamentos
            </h4>
            <ul className={styles.bulletList}>
              <li>
                Ajustado a responsividade dos cards de Treinamento, melhorando a visibilidade das
                informações.
              </li>
              <li>Correção no agendamento de treinamentos no dia 29/02.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Tela Agenda Visão Geral
            </h4>
            <ul className={styles.bulletList}>
              <li>Correção na disponibilidade da agenda nos domingos.</li>
            </ul>
            <h4 className={styles.subtitle}>
              <span className={styles.symbol}>📌</span> Outros
            </h4>
            <ul className={styles.bulletList}>
              <li>Correções de bugs e outros ajustes.</li>
            </ul>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Modal>
  );
}
