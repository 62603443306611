/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, DatePicker, Form, Input, InputNumber, Select } from 'antd';

import Utils from '../../../../Assets/Scripts/Utils';
import UploadFileField from '../UploadFileField';

function FormFinanceFieldsComponent({
  fieldsPermissions,
  reasonNoBillingOptions,
  trainingCenterOptions,
  handleTaskUpdate,
  form,
  task,
  setUploadFileType,
  setUploadFileModalOpen,
  isTaskReadOnly,
  isEditing,
}) {
  return (
    <Col span={24}>
      <Card title="Financeiro" bordered={false} className="task-form-section-card">
        {/* Valor de Treinamento */}
        <Col span={24}>
          <Form.Item
            name="trainingValue"
            label="Valor de Treinamento"
            rules={[
              {
                required: fieldsPermissions?.TrainingValue.isRequired,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <InputNumber
              min={0}
              step={0.0001}
              formatter={Utils.priceFormatter}
              parser={Utils.priceParser}
              style={{ width: '100%' }}
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Data de Previsão de Faturamento */}
        <Col span={24}>
          <Form.Item
            name="billingDeadlineDate"
            label="Data de Previsão de Faturamento"
            rules={[
              {
                required: fieldsPermissions?.BillingDeadlineDate.isRequired,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{
                borderRadius: 16,
              }}
              disabled={
                fieldsPermissions?.BillingDeadlineDate.access !== 2 || isTaskReadOnly || !isEditing
              }
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Razão de não Faturamento */}
        <Col span={24}>
          <Form.Item
            label="Razão de não Faturamento"
            name="reasonNoBilling"
            rules={[
              {
                required: fieldsPermissions?.ReasonNoBilling.isRequired,
                message: 'Campo obrigatório!',
              },
            ]}
            getValueFromEvent={(_, object) => object}
          >
            <Select
              options={reasonNoBillingOptions.map((reasonNoBillingOptions) => ({
                label: reasonNoBillingOptions.name,
                value: reasonNoBillingOptions.id,
              }))}
              allowClear
              placeholder="o campo está vazio"
              optionFilterProp="label"
              showSearch
              dropdownStyle={{ borderRadius: 16 }}
              disabled={
                fieldsPermissions?.ReasonNoBilling.access !== 2 || isTaskReadOnly || !isEditing
              }
            />
          </Form.Item>
        </Col>

        {/* NF Treinamento */}
        <Col span={24}>
          <Form.Item label="NF Treinamento" name="nfTraining">
            <UploadFileField
              handleTaskUpdate={handleTaskUpdate}
              form={form}
              task={task}
              fieldName="nfTraining"
              fieldPermission={fieldsPermissions?.NFTraining}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
              setUploadFileType={setUploadFileType}
              setUploadFileModalOpen={setUploadFileModalOpen}
            />
          </Form.Item>
        </Col>

        {/* Número Nota Fiscal */}
        <Col span={24}>
          <Form.Item
            name="invoiceNumber"
            label="Número Nota Fiscal"
            rules={[
              {
                required: fieldsPermissions?.InvoiceNumber.isRequired,
                message: 'Número Nota Fiscal',
              },
            ]}
          >
            <Input
              disabled={
                fieldsPermissions?.InvoiceNumber.access !== 2 || isTaskReadOnly || !isEditing
              }
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Data de Emissão da Nota Fiscal */}
        <Col span={24}>
          <Form.Item
            name="invoiceEmissionDate"
            label="Data de Emissão da Nota Fiscal"
            rules={[
              {
                required: fieldsPermissions?.InvoiceEmissionDate.isRequired,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              style={{
                borderRadius: 16,
              }}
              disabled={
                fieldsPermissions?.InvoiceEmissionDate.access !== 2 || isTaskReadOnly || !isEditing
              }
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Emissor Nota Fiscal */}
        <Col span={24}>
          <Form.Item
            label="Emissor Nota Fiscal"
            name="invoiceIssuer"
            rules={[
              {
                required: fieldsPermissions?.InvoiceIssuer.isRequired,
                message: 'Campo obrigatório!',
              },
            ]}
            getValueFromEvent={(_, object) => ({
              ...object,
              name: object.commercialName,
              id: object.id,
            })}
          >
            <Select
              options={trainingCenterOptions}
              allowClear
              placeholder="o campo está vazio"
              optionFilterProp="label"
              showSearch
              dropdownStyle={{ borderRadius: 16 }}
              disabled={
                fieldsPermissions?.InvoiceIssuer.access !== 2 || isTaskReadOnly || !isEditing
              }
            />
          </Form.Item>
        </Col>

        {/* Valor Nota Fiscal Total */}
        <Col span={24}>
          <Form.Item
            name="totalInvoiceValue"
            label="Valor Nota Fiscal Total"
            rules={[
              {
                required: fieldsPermissions?.TotalInvoiceValue.isRequired,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <InputNumber
              min={0}
              step={0.0001}
              formatter={Utils.priceFormatter}
              parser={Utils.priceParser}
              style={{ width: '100%' }}
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Valor Nota Fiscal Parcial */}
        <Col span={24}>
          <Form.Item
            name="partialInvoiceValue"
            label="Valor Nota Fiscal Parcial"
            rules={[
              {
                required: fieldsPermissions?.PartialInvoiceValue.isRequired,
                message: 'Campo obrigatório',
              },
            ]}
          >
            <InputNumber
              min={0}
              step={0.0001}
              formatter={Utils.priceFormatter}
              parser={Utils.priceParser}
              style={{ width: '100%' }}
              placeholder="o campo está vazio"
            />
          </Form.Item>
        </Col>

        {/* Observação Omie */}
        <Col span={24}>
          <Form.Item name="omieNotes" label="Observação Omie">
            <Input disabled={true} placeholder="o campo está vazio" />
          </Form.Item>
        </Col>
      </Card>
    </Col>
  );
}

export default FormFinanceFieldsComponent;
