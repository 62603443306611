/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, Form, message, Row, Tabs } from 'antd';

import Utils from '../../../Assets/Scripts/Utils';
import TaskComment from '../../../Components/Schedule/TaskComment';
import { updateCombinedInstructorList } from '../API/ScheduleSchedulingAPI';

import FormCertificateFields from './FormFields/FormCertificateFields';
import FormFinanceFields from './FormFields/FormFinanceFields';
import FormGeneralFields from './FormFields/FormGeneralFields';
import FormLogisticFields from './FormFields/FormLogisticFields';
import FormTransportFields from './FormFields/FormTransportFields';

import './TaskForm.scss';

function TaskForm({
  form,
  task,
  isLoading,
  fieldsPermissions,
  stages,
  trainingsOptions,
  ctResources,
  transportOptions,
  reasonNoBillingOptions,
  trainingCenterOptions,
  instructorOptions,
  driverOptions,
  vehicleOptions,
  productOptions,
  certificateModelOptions,
  comments,
  handleTaskUpdate,
  setUploadFileType,
  setUploadFileModalOpen,
  isTaskReadOnly,
  isEditing,
}) {
  const [combinedInstructorList, setCombinedInstructorList] = useState([]);

  const commentsActivities = comments.filter(({ activityType }) => activityType === 1);

  const fetchData = async () => {
    try {
      const [instructorRes] = await Promise.all([
        updateCombinedInstructorList(instructorOptions, task),
      ]);
      setCombinedInstructorList(instructorRes);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar os dados do treinamento!');
    }
  };

  useEffect(() => {
    if (task) {
      fetchData();
      form.setFieldsValue({ ...task });
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      disabled={isLoading || isTaskReadOnly || !isEditing}
      autoComplete="off"
      style={{ maxWidth: '100%', margin: '0 auto' }}
      className="training-card-form"
    >
      <Row gutter={[24]}>
        <Col span={12}>
          <Row>
            {/* GERAL */}
            <FormGeneralFields
              fieldsPermissions={fieldsPermissions}
              form={form}
              task={task}
              stages={stages}
              ctResources={ctResources}
              combinedInstructorList={combinedInstructorList}
              trainingsOptions={trainingsOptions}
              handleTaskUpdate={handleTaskUpdate}
              setUploadFileType={setUploadFileType}
              setUploadFileModalOpen={setUploadFileModalOpen}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
            />

            {/* Logística */}
            <FormLogisticFields
              fieldsPermissions={fieldsPermissions}
              handleTaskUpdate={handleTaskUpdate}
              form={form}
              task={task}
              setUploadFileType={setUploadFileType}
              setUploadFileModalOpen={setUploadFileModalOpen}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
            />

            {/* Transporte ======== */}
            <FormTransportFields
              fieldsPermissions={fieldsPermissions}
              transportOptions={transportOptions}
              driverOptions={driverOptions}
              vehicleOptions={vehicleOptions}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
              stages={stages}
              task={task}
            />

            {/* Financeiro ========== */}
            <FormFinanceFields
              fieldsPermissions={fieldsPermissions}
              reasonNoBillingOptions={reasonNoBillingOptions}
              trainingCenterOptions={trainingCenterOptions}
              handleTaskUpdate={handleTaskUpdate}
              form={form}
              task={task}
              setUploadFileType={setUploadFileType}
              setUploadFileModalOpen={setUploadFileModalOpen}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
            />

            {/* Certificado ========== */}
            <FormCertificateFields
              fieldsPermissions={fieldsPermissions}
              form={form}
              task={task}
              isTaskReadOnly={isTaskReadOnly}
              isEditing={isEditing}
              certificateModelOptions={certificateModelOptions}
              productOptions={productOptions}
              handleTaskUpdate={handleTaskUpdate}
            />
          </Row>
        </Col>

        {/* ATIVIDADES */}
        {fieldsPermissions?.Comments.access !== 0 &&
          (commentsActivities?.length > 0 || comments?.length > 0) && (
            <Col span={12}>
              <Tabs>
                {commentsActivities?.length > 0 && (
                  <Tabs.TabPane tab="Atividade Resumida" key="activityResumed">
                    <TaskComment comments={commentsActivities} />
                  </Tabs.TabPane>
                )}

                {comments?.length > 0 && (
                  <Tabs.TabPane tab="Atividade Completa" key="activityFull">
                    <TaskComment comments={comments} />
                  </Tabs.TabPane>
                )}
              </Tabs>
            </Col>
          )}
      </Row>
    </Form>
  );
}

export default TaskForm;
