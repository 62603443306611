/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
import { message } from 'antd';
import moment from 'moment';

import Utils from '../../../Assets/Scripts/Utils';
import ScheduleUtils from '../../../Components/Schedule/ScheduleUtils';
import { api } from '../../../Services/axiosService';

import SchedulingFunctions from './SchedulingFunctions';

export const fetchProduct = async () => {
  try {
    const response = await api.get(`/Product`);
    return [
      ...response.data.map((product) => ({
        ...product,
        label: product.name,
        value: product.id,
      })),
    ];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Produtos!');
    return [];
  }
};

export const generateCertificate = async (selectedRowKeys, taskList, subTableSelectedRowKeys) => {
  // Map selected tasks to the CertificateTableRowModel structure

  const certificateTableModel = selectedRowKeys
    .map((taskId) => {
      const task = taskList.find((t) => t.id === taskId);
      if (!task || !task.queryStudentList) return null;

      // Filter and collect student IDs for the current task
      const studentIds = task.queryStudentList
        .filter((student) => subTableSelectedRowKeys.includes(student.id))
        .map((student) => student.id);

      return {
        TaskId: taskId,
        StudentIdList: studentIds,
      };
    })
    .filter(Boolean); // Filter out any null entries

  if (certificateTableModel.length === 0) {
    message.warn('Oops. Nenhum registro válido para gerar certificado!');
    return;
  }

  const certificateEndpointModel = { CertificateTableRowList: certificateTableModel };

  try {
    const response = await fetch(`${process.env.REACT_APP_HOST}/Task/GenerateCertificate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('conecta__token')}`,
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/zip', // Accept header should expect a ZIP file format
      },
      body: JSON.stringify(certificateEndpointModel),
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error('Failed to generate certificates');
    }

    const blob = await response.blob();
    const fileName = `Certificates_${moment(new Date()).format('YYYY-MM-DD')}.zip`; // Correct filename and extension
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    message.success('Download do certificado realizado com sucesso!');
  } catch (error) {
    console.error('Error generating certificates:', error);
    message.error('Oops. Algo deu errado ao tentar baixar o certificado!');
  }
};

export const fetchTaskStudents = async (taskList) => {
  const batchSize = 30;
  let allStudents = [];

  // Dividir a taskList em subconjuntos de 30
  for (let i = 0; i < taskList.length; i += batchSize) {
    const batch = taskList.slice(i, i + batchSize);

    // Construir a string de IDs para a query
    const taskIds = batch.map((task) => task.id).join(',');

    try {
      // Requisição para a API com os IDs de tarefa
      const response = await api.get(
        `/TaskStudent?filters[0].Field=TaskId&filters[0].Condition=NUMBER.IN&filters[0].Value=${taskIds}`
      );

      // Adicionar os resultados à lista total
      allStudents = [
        ...allStudents,
        ...response.data.map((student) => ({
          ...student,
          label: student.name,
          value: student.id,
        })),
      ];
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao tentar buscar os Estágios!');
    }
  }

  return allStudents;
};

const getProductNameComplement = (product) => {
  if (product?.complement && product?.complement !== '') {
    return `${product?.name}${` - ${product?.complement}` ?? ''}`;
  }

  return product?.name;
};

const getProductName = (product) => {
  const customProductList = ScheduleUtils.getCustomProductList();
  const isCustomProduct = customProductList.includes(product?.id);

  if (isCustomProduct) {
    return product.complement ? product.complement : product.name;
  }

  return getProductNameComplement(product);
};

export const fetchTasks = async (filters = null) => {
  try {
    const url = '/Task/TaskList';
    const response = await api.post(url, filters);
    const tasks = response.data;

    const mappedTasks = tasks
      .map((task, index) => {
        const productName = getProductName(task.product);
        const formattedStartDate = task.startDate
          ? moment(task.startDate).format('DD/MM/YYYY')
          : null;

        return {
          ...task,
          text: productName,
          index,
          startDateFormatted: formattedStartDate,
          oldMainInstructorId: task?.mainInstructor?.userId,
          oldAssistantInstructorId: task?.assistantInstructor?.userId,
          notHaveCertificate: !!task.notHaveCertificate,
          productWithComplement: getProductNameComplement(task.product),
        };
      })
      .sort((a, b) => a.key - b.key);

    return mappedTasks;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os Treinamentos!');
    return [];
  }
};

export const fetchQuotes = async (dealIds) => {
  const batchSize = 30;
  const results = [];

  if (dealIds && dealIds.length > 0) {
    for (let i = 0; i < dealIds.length; i += batchSize) {
      const batch = dealIds.slice(i, i + batchSize);

      try {
        const response = await api.get(
          `/Quote?filters[0].Field=DealId&filters[0].Condition=NUMBER.IN&filters[0].Value=${batch.join(
            ','
          )}`
        );
        results.push(...response.data);
      } catch (error) {
        Utils.logError(error);
        message.error('Oops. Algo deu errado ao buscar os dados do Negócio!');
        throw error;
      }
    }
  }

  return results;
};

export const fetchTaskById = async (taskId) => {
  try {
    const tasks = await api
      .get(`/Task?filters[0].Field=Id&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${taskId}`)
      .then((res) => res.data);

    if (tasks?.length > 0) {
      const dealIds = [...new Set(tasks?.map(({ dealId }) => dealId))];
      const quotesRes = await fetchQuotes(dealIds);
      const studentListRes = await fetchTaskStudents(tasks);

      const mappedTasks = tasks.map((task, index) => {
        const quote = quotesRes?.find(({ dealId }) => dealId === task.dealId);
        const queryStudentList = studentListRes?.filter(({ taskId }) => taskId === task.id);

        // Calcula a Duração do Treinamento
        let durationHours = 0;
        if (task.startHour && task.endHour) {
          const durationMinutes = moment(new Date(task.endHour)).diff(
            new Date(task.startHour),
            'minutes'
          );
          durationHours = durationMinutes / 60;
        }
        const productName = getProductName(task.product);
        return {
          ...quote,
          ...task,
          text: productName,
          index,
          durationHours,
          oldMainInstructorId: task?.mainInstructor?.userId,
          oldAssistantInstructorId: task?.assistantInstructor?.userId,
          queryStudentList,
          notHaveCertificate: !!task.notHaveCertificate,
          productWithComplement: getProductNameComplement(task.product),
        };
      });

      return mappedTasks;
    }

    return [];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao buscar os dados do Treinamento!');
    return [];
  }
};

export const updateCombinedInstructorList = async (instructorOptions, taskData) => {
  try {
    const availableInstructors = await SchedulingFunctions.fetchInstructorsAvailability(
      instructorOptions,
      taskData.startDate,
      taskData.endDate
    );

    if (availableInstructors?.length > 0) {
      const newCombinedInstructorList = instructorOptions?.map((instructor) => {
        const availability = availableInstructors.find((avail) => avail.id === instructor.id);
        const trainingGroupsList = instructor?.trainingKind?.map(({ id }) => id);

        return {
          ...instructor,
          label: instructor.name,
          value: instructor.id,
          hasTrainings: availability?.hasTrainings ?? false,
          isBlocked: availability?.isBlocked ?? false,
          disabled: availability?.isBlocked ?? false,
          trainingGroupsList,
        };
      });

      return newCombinedInstructorList;
    }

    return [];
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar a lista de instrutores!');
    return [];
  }
};

export const fetchComments = async (newTask) => {
  try {
    // Busca comentários relacionados à tarefa e ao negócio
    const [commentsTaskResponse, commentsQuoteResponse] = await Promise.all([
      api.get(
        `/TaskComment?filters[0].Field=TaskId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.id}`
      ),
      api.get(
        `/TaskComment?filters[0].Field=DealId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${newTask.dealId}`
      ),
    ]);

    const commentsCombined = [...commentsTaskResponse.data, ...commentsQuoteResponse.data]?.sort(
      (a, b) => a.id - b.id
    );
    return commentsCombined;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar os comentários!');
    return [];
  }
};

export const fetchSchedulingScheduleFilter = async () => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;
    const filterType = 'schedulingSchedule';

    const response = await api.get(
      `/ScreenFilter?filters[0].Field=UserId&filters[0].Condition=NUMBER.EQUAL&filters[0].Value=${userId}&filters[1].Field=Type&filters[1].Condition=EQUAL&filters[1].Value=${filterType}`
    );

    if (response.data?.length > 0) {
      const [filter] = response.data;
      filter.filters = JSON.parse(filter.filters);

      if (filter.filters.period) {
        const periodStart = filter.filters?.period[0];
        const periodEnd = filter.filters?.period[1];
        filter.filters.period = [moment(periodStart), moment(periodEnd)];
      }

      return filter;
    }

    return null;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar buscar os filtros!');
    return [];
  }
};

export const addSchedulingScheduleFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'schedulingSchedule';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.post('/ScreenFilter', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar salvar os filtros!');
    return [];
  }
};

export const updateSchedulingScheduleFilter = async (submitData) => {
  try {
    const userId = JSON.parse(localStorage.getItem('conecta__userData') ?? {})?.id;

    submitData.userId = userId;
    submitData.type = 'schedulingSchedule';
    submitData.filters = JSON.stringify(submitData.filters);

    const response = await api.put('/ScreenFilter', submitData);
    return response.data;
  } catch (error) {
    Utils.logError(error);
    message.error('Oops. Algo deu errado ao tentar atualizar os filtros!');
    return [];
  }
};
