/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, Dropdown, Menu, message, Skeleton, Tooltip } from 'antd';

import {
  CommentOutlined,
  CopyOutlined,
  EditOutlined,
  ExportOutlined,
  FileProtectOutlined,
  MailOutlined,
} from '@ant-design/icons';

import EmailConfirmPopup from '../../../Components/EmailConfirmPopup/EmailConfirmPopup';
import OccurrencesPopup from '../../../Components/OccurrencePopup/OccurrencePopup';
import CertificateSettingsModal from '../../Certificate/Components/CertificateSettingsModal';
import GenerateCertificateModal from '../../Certificate/Components/GenerateCertificateModal';
import UploadStudentsModal from '../../Certificate/Components/UploadStudentsModal';

import './TaskDrawerExtra.scss';

function TaskDrawerExtraComponent({
  isLoading,
  fieldsPermissions,
  form,
  task,
  isTaskReadOnly,
  setIsEditing,
  certificateModelOptions,
  productOptions,
  handleTaskUpdate,
  setComments,
}) {
  const [occurrencesPopupVisible, setOccurrencesPopupVisible] = useState(false);
  const [emailConfirmPopupVisible, setEmailConfirmPopupVisible] = useState(false);
  const [confirmEmailType, setConfirmEmailType] = useState(null);
  const [isCertificateSettingsModalOpen, setIsCertificateSettingsModalOpen] = useState(false);
  const [isUploadStudentsModalOpen, setIsUploadStudentsModalOpen] = useState(false);
  const [isGenerateCertificateModalOpen, setIsGenerateCertificateModalOpen] = useState(false);

  const openEmailConfirmPopup = (type) => {
    setConfirmEmailType(type);
    setEmailConfirmPopupVisible(true);
  };

  // Extrair dados do task para usar nas condições
  const stageId = task.stage?.id;
  const isInCompany = task.trainingType === 'In Company';
  const hasInstructor = task.mainInstructor?.userId;
  const hasTransport = task.trainingTransportation === 'Incluso';
  const hasDriverVehicleTransport =
    task.transportCompany?.id && task.driverList && task.vehicleList;
  const isDriverVehicleSameSize = task.driverList?.length === task.vehicleList?.length;
  const invalidStages = [1, 6, 10, 12]; // 1 = Aguardando, 6 = Reagendar, 10 = Pré-Reservado, 12 = Reservado
  const isValidStage = !invalidStages.includes(stageId);

  const shouldDisableCertificateGeneration = () => {
    const hasAccess = fieldsPermissions?.GenerateCertificateBtn?.access >= 1;
    const hasStudents = task.studentList?.length > 0 && task.queryStudentList?.length > 0;
    return !task.certificateModel || !hasStudents || !hasAccess || !isValidStage || isTaskReadOnly;
  };

  const emailMenu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => openEmailConfirmPopup(1)}
        disabled={fieldsPermissions?.EmailConfirmTrainingButton?.access < 1 || !isValidStage}
      >
        Enviar E-mail Confirmação Treinamento
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => openEmailConfirmPopup(2)}
        disabled={
          fieldsPermissions?.EmailConfirmTransportButton?.access < 1 ||
          !isValidStage ||
          !hasDriverVehicleTransport ||
          !hasTransport
        }
      >
        Enviar E-mail Confirmação Transporte
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => openEmailConfirmPopup(3)}
        disabled={
          fieldsPermissions?.EmailConfirmInstructorButton?.access < 1 ||
          !isValidStage ||
          !isInCompany ||
          !hasInstructor
        }
      >
        Enviar E-mail Confirmação Instrutor
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => openEmailConfirmPopup(4)}
        disabled={
          fieldsPermissions?.EmailConfirmDriverButton?.access < 1 ||
          !isValidStage ||
          !hasDriverVehicleTransport ||
          !isDriverVehicleSameSize ||
          !hasTransport
        }
      >
        Enviar E-mail Confirmação Motorista
      </Menu.Item>
    </Menu>
  );

  const certificateMenu = (
    <Menu>
      {fieldsPermissions?.CertificateTrainingSettingsBtn.access !== 0 && (
        <Menu.Item
          key="1"
          onClick={() => setIsUploadStudentsModalOpen(true)}
          disabled={
            fieldsPermissions?.CertificateTrainingSettingsBtn?.access < 1 ||
            !isValidStage ||
            isTaskReadOnly
          }
        >
          Importar Lista de Alunos
        </Menu.Item>
      )}

      {fieldsPermissions?.ImportStudentListBtn.access !== 0 && (
        <Menu.Item
          key="2"
          onClick={() => setIsCertificateSettingsModalOpen(true)}
          disabled={
            fieldsPermissions?.ImportStudentListBtn?.access < 1 || !isValidStage || isTaskReadOnly
          }
        >
          Configurar Certificado
        </Menu.Item>
      )}

      {fieldsPermissions?.GenerateCertificateBtn.access !== 0 && (
        <Menu.Item
          key="3"
          onClick={() =>
            setIsGenerateCertificateModalOpen('certificateWithStudents', form.getFieldValue('id'))
          }
          disabled={shouldDisableCertificateGeneration()}
        >
          Gerar Certificado
        </Menu.Item>
      )}
    </Menu>
  );

  const onCopyDetails = () => {
    const text =
      `Id: ${task.id}\n` +
      `Id Negócio: ${task.dealId}\n` +
      `Id Turma: ${task.slotClassId ?? ''}\n` +
      `Título: ${task.cardTitle}\n` +
      `Negócio: ${task.dealTitle}\n` +
      `Cliente: ${task.dealClient}\n` +
      `Responsável: ${task.quoteResponsible}\n` +
      `Produto: ${task.text}\n` +
      `Tipo: ${task.trainingType}\n` +
      `CT: ${task.trainingCenter ?? ''}`;

    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.info('Detalhes do Treinamento copiados para a área de transferência!');
      })
      .catch((err) => {
        console.error(err);
        message.error('Oops. Algo deu errado ao copiar os detalhes do treinamento!');
      });
  };

  if (isLoading) {
    return (
      <div className="task-drawer-skeleton-wrapper">
        <Skeleton.Avatar style={{ margin: 0 }} active={true} size="default" shape="circle" />
        <Skeleton.Avatar style={{ margin: 0 }} active={true} size="default" shape="circle" />
        <Skeleton.Avatar style={{ margin: 0 }} active={true} size="default" shape="circle" />
        <Skeleton.Avatar style={{ margin: 0 }} active={true} size="default" shape="circle" />
        <Skeleton.Avatar style={{ margin: 0 }} active={true} size="default" shape="circle" />
      </div>
    );
  }

  if (!isLoading) {
    return (
      <>
        <div style={{ display: 'flex', gap: 8 }}>
          {!isTaskReadOnly && (
            <Tooltip title="Editar">
              <Button onClick={() => setIsEditing(true)} icon={<EditOutlined />} />
            </Tooltip>
          )}

          <Dropdown overlay={emailMenu} disabled={isLoading} trigger={['hover']}>
            <Button icon={<MailOutlined />} />
          </Dropdown>

          {(fieldsPermissions?.CertificateTrainingSettingsBtn.access !== 0 ||
            fieldsPermissions?.ImportStudentListBtn.access !== 0 ||
            fieldsPermissions?.GenerateCertificateBtn.access !== 0) && (
            <Dropdown
              overlay={certificateMenu}
              disabled={isLoading || isTaskReadOnly}
              trigger={['hover']}
            >
              <Button icon={<FileProtectOutlined />} />
            </Dropdown>
          )}

          <Tooltip title="Adicionar Ocorrência">
            <Button
              onClick={() => setOccurrencesPopupVisible(true)}
              icon={<CommentOutlined />}
              disabled={isLoading || isTaskReadOnly}
            />
          </Tooltip>

          <Tooltip title="Copiar Detalhes do Treinamento">
            <Button onClick={onCopyDetails} icon={<CopyOutlined />} />
          </Tooltip>

          <Tooltip title="Abrir no Bitrix24">
            <Button
              onClick={() =>
                window.open(
                  `https://conecta.bitrix24.com.br/crm/deal/details/${task.dealId}/`,
                  '_blank'
                )
              }
              icon={<ExportOutlined />}
            />
          </Tooltip>
        </div>

        <OccurrencesPopup
          isVisible={occurrencesPopupVisible}
          onClose={() => setOccurrencesPopupVisible(false)}
          currentOpenTask={task}
          setComments={setComments}
          task={task}
        />

        {emailConfirmPopupVisible && (
          <EmailConfirmPopup
            onClose={() => setEmailConfirmPopupVisible(false)}
            currentOpenTask={task}
            type={confirmEmailType}
          />
        )}

        {isCertificateSettingsModalOpen && (
          <CertificateSettingsModal
            form={form}
            setIsOpen={setIsCertificateSettingsModalOpen}
            certificateModelOptions={certificateModelOptions}
            productOptions={productOptions}
            taskList={[task]}
            selectedRowKeys={[task.id]}
            isSchedulingScreen={true}
            handleTaskUpdate={handleTaskUpdate}
          />
        )}

        {isUploadStudentsModalOpen && (
          <UploadStudentsModal
            form={form}
            setIsOpen={setIsUploadStudentsModalOpen}
            taskList={[task]}
            selectedRowKeys={[task.id]}
            isSchedulingScreen={true}
            handleTaskUpdate={handleTaskUpdate}
          />
        )}

        {isGenerateCertificateModalOpen && (
          <GenerateCertificateModal
            setIsOpen={setIsGenerateCertificateModalOpen}
            taskList={[task]}
            selectedTaskIds={[task.id]}
            showTable={true}
          />
        )}
      </>
    );
  }
}

export default TaskDrawerExtraComponent;
