/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-await-in-loop */
import { message } from 'antd';
import moment from 'moment';
import { renderToStaticMarkup } from 'react-dom/server';

import Utils from '../../Assets/Scripts/Utils';
import { api } from '../../Services/axiosService';

import TaskComment from './TaskComment';

class TaskCommentFunctions {
  static async addTaskComment(
    task,
    event,
    eventSource,
    description,
    reasonId,
    reason,
    percentageToBeCharged
  ) {
    try {
      let titleEvent = '';
      let titleSource = '';
      const eventSourceFormatted = eventSource.charAt(0).toUpperCase() + eventSource.slice(1);

      switch (event) {
        case 'TaskAccepted':
          titleEvent = 'Aceito';
          break;
        case 'TaskDenied':
          titleEvent = 'Negado';
          break;
        case 'TaskRemoved':
          titleEvent = 'Removido';
          break;
        case 'TaskStarted':
          titleEvent = 'Iniciado';
          break;
        case 'TaskFinished':
          titleEvent = 'Concluído';
          break;
        case 'TaskIntercurrence':
          titleEvent = 'Intercorrência';
          break;
        case 'TaskFinishedBeforeMargin':
          titleEvent = 'Conclusão Antecipada';
          break;
        case 'TaskEditedDelayed':
          titleEvent = 'Concluído Com Atraso (Editado)';
          break;
        default:
          break;
      }

      switch (eventSourceFormatted) {
        case 'Scheduling':
          titleSource = 'Agendamento';
          break;
        case 'CT':
          titleSource = 'Centro de Treinamento';
          break;
        case 'MainInstructor':
          titleSource = 'Instrutor';
          break;
        case 'AssistantInstructor':
          titleSource = 'Instrutor Auxiliar';
          break;
        case 'Driver':
          titleSource = 'Motorista';
          break;
        default:
          break;
      }

      const userData = JSON.parse(localStorage.getItem('conecta__userData'));
      const data = {
        taskId: task.id,
        title: `Treinamento ${titleEvent} (${titleSource})`,
        userName: userData.name,
        userAvatar: userData.profilePic,
        description,
        sourceId: parseInt(userData.id, 10),
        sourceType: eventSourceFormatted + event,
        reasonId,
        reason,
        percentageToBeCharged,
        activityType: 1,
      };

      await api.post('/TaskComment', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar a Atividade!');
    }
  }

  static formatCurrency(value) {
    if (value === null || value === undefined || value === '') {
      return (0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    const number = parseFloat(value);

    if (!Number.isFinite(number)) {
      throw new Error('Invalid input: Not a valid number.');
    }

    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  static async fetchTaskComment(newTaskData, title, description, event, eventSource, activityType) {
    try {
      const userData = JSON.parse(localStorage.getItem('conecta__userData'));

      const data = {
        taskId: newTaskData.id,
        title,
        userName: userData.name,
        userAvatar: userData.profilePic,
        description,
        sourceId: parseInt(userData.id, 10),
        sourceType: eventSource + event,
        activityType,
      };

      await api.post('/TaskComment', data).then((res) => res);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao salvar uma Atividade!');
    }
  }

  static async addTaskCommentActivities(newTaskData, oldTaskData, eventSource) {
    try {
      const getValueOrEmpty = (value) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        value?.toString() !== 'Invalid Date'
          ? value
          : '"vazio"';

      if (!oldTaskData?.mainInstructor?.userName && newTaskData?.mainInstructor?.userName) {
        const event = 'FieldInserted';
        const title = 'Inserção de Campo';
        const description = `Instrutor inserido: <b>${newTaskData.mainInstructor.userName}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      } else if (
        newTaskData?.mainInstructor?.userName &&
        newTaskData?.mainInstructor?.userName !== oldTaskData?.mainInstructor?.userName
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Instrutor alterado de <b>${getValueOrEmpty(
          oldTaskData.mainInstructor.userName
        )}</b> para <b>${newTaskData.mainInstructor.userName}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }
      if (
        !oldTaskData?.assistantInstructor?.userName &&
        newTaskData?.assistantInstructor?.userName
      ) {
        const event = 'FieldInserted';
        const title = 'Inserção de Campo';
        const description = `Instrutor Auxiliar inserido: <b>${newTaskData.assistantInstructor.userName}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      } else if (
        newTaskData?.assistantInstructor?.userName &&
        newTaskData?.assistantInstructor?.userName !== oldTaskData?.assistantInstructor?.userName
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Instrutor Auxiliar alterado de <b>${getValueOrEmpty(
          oldTaskData.assistantInstructor.userName
        )}</b> para <b>${newTaskData.assistantInstructor.userName}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      if (newTaskData?.stage?.name !== oldTaskData?.stage?.name) {
        const event = 'StatusChanged';
        const title = 'Alteração de Status';
        const description = `Status alterado de <b>${getValueOrEmpty(
          oldTaskData.stage?.name
        )}</b> para <b>${newTaskData.stage?.name}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      if (newTaskData?.transportCompany?.name !== oldTaskData?.transportCompany?.name) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Transportadora alterado de <b>${getValueOrEmpty(
          oldTaskData.transportCompany?.name
        )}</b> para <b>${newTaskData.transportCompany?.name}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      if (newTaskData?.nameResponsibleConfirmation !== oldTaskData?.nameResponsibleConfirmation) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Nome Responsável Confirmação alterado de <b>${getValueOrEmpty(
          oldTaskData.nameResponsibleConfirmation
        )}</b> para <b>${newTaskData.nameResponsibleConfirmation}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      if (
        moment(new Date(newTaskData?.confirmationDate)).format('DD/MM/YYYY') !==
        moment(new Date(oldTaskData?.confirmationDate)).format('DD/MM/YYYY')
      ) {
        const oldConfirmationDate = oldTaskData?.confirmationDate
          ? moment(new Date(oldTaskData?.confirmationDate)).format('DD/MM/YYYY')
          : '"vazio"';
        const newConfirmationDate = newTaskData?.confirmationDate
          ? moment(new Date(newTaskData?.confirmationDate)).format('DD/MM/YYYY')
          : '"vazio"';

        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Data de Confirmação alterado de <b>${oldConfirmationDate}
          </b> para <b>${newConfirmationDate}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      if (
        moment(new Date(newTaskData?.dateScheduling)).format('DD/MM/YYYY') !==
        moment(new Date(oldTaskData?.dateScheduling)).format('DD/MM/YYYY')
      ) {
        const oldDateScheduling = oldTaskData?.dateScheduling
          ? moment(new Date(oldTaskData?.dateScheduling)).format('DD/MM/YYYY')
          : '"vazio"';
        const newDateScheduling = newTaskData?.dateScheduling
          ? moment(new Date(newTaskData?.dateScheduling)).format('DD/MM/YYYY')
          : '"vazio"';

        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Data de Agendamento alterado de <b>${oldDateScheduling}
          </b> para <b>${newDateScheduling}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      // Horário de Inicio
      if (
        newTaskData?.startHour &&
        newTaskData?.startHour !== '' &&
        moment(new Date(newTaskData?.startHour)).format('HH:mm') !==
          moment(new Date(oldTaskData?.startHour)).format('HH:mm')
      ) {
        const oldstartHour = oldTaskData?.startHour
          ? moment(new Date(oldTaskData?.startHour)).format('HH:mm')
          : '"vazio"';
        const newstartHour = newTaskData?.startHour
          ? moment(new Date(newTaskData?.startHour)).format('HH:mm')
          : '"vazio"';

        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Horário de Inicio alterado de <b>${oldstartHour}</b> para <b>${newstartHour}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }

      // Data de Emissão da Nota Fiscal
      if (
        moment(new Date(newTaskData?.invoiceEmissionDate)).format('DD/MM/YYYY') !==
        moment(new Date(oldTaskData?.invoiceEmissionDate)).format('DD/MM/YYYY')
      ) {
        const oldDate = oldTaskData?.invoiceEmissionDate
          ? moment(new Date(oldTaskData?.invoiceEmissionDate)).format('DD/MM/YYYY')
          : '"vazio"';
        const newDate = newTaskData?.invoiceEmissionDate
          ? moment(new Date(newTaskData?.invoiceEmissionDate)).format('DD/MM/YYYY')
          : '"vazio"';

        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Data de Emissão da Nota Fiscal alterado de <b>${oldDate}
          </b> para <b>${newDate}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar as Atividades!');
    }
  }

  static async addTaskCommentHistory(newTaskData, oldTaskData, eventSource) {
    try {
      const getValueOrEmpty = (value) =>
        value !== null &&
        value !== undefined &&
        value !== '' &&
        value?.toString() !== 'Invalid Date'
          ? value
          : '"vazio"';

      if (newTaskData?.instructorValue !== oldTaskData?.instructorValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.instructorValue);
        const newVal = this.formatCurrency(newTaskData.instructorValue);
        const description = `Campo Valor Instrutor alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.assistantInstructorValue !== oldTaskData?.assistantInstructorValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.assistantInstructorValue);
        const newVal = this.formatCurrency(newTaskData.assistantInstructorValue);
        const description = `Campo Valor Instrutor Auxiliar alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.reasonNoBilling?.name !== oldTaskData?.reasonNoBilling?.name) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Motivo da Não Cobrança alterado de <b>${getValueOrEmpty(
          oldTaskData.reasonNoBilling?.name
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.reasonNoBilling?.name)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.trainingValue !== oldTaskData?.trainingValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.trainingValue);
        const newVal = this.formatCurrency(newTaskData.trainingValue);
        const description = `Campo Valor do Treinamento alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.phoneResponsibleConfirmation !== oldTaskData?.phoneResponsibleConfirmation) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Telefone de Confirmação do Responsável alterado de <b>${getValueOrEmpty(
          oldTaskData.phoneResponsibleConfirmation
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.phoneResponsibleConfirmation)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        newTaskData?.namePersonResponsibleConfirmation !==
        oldTaskData?.namePersonResponsibleConfirmation
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Nome Responsável Confirmação alterado de <b>${getValueOrEmpty(
          oldTaskData.namePersonResponsibleConfirmation
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.namePersonResponsibleConfirmation)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.responsibleEmailConfirmation !== oldTaskData?.responsibleEmailConfirmation) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo E-mail Responsável Confirmação alterado de <b>${getValueOrEmpty(
          oldTaskData.responsibleEmailConfirmation
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.responsibleEmailConfirmation)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.confirmedBy !== oldTaskData?.confirmedBy) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Confirmado Por alterado de <b>${getValueOrEmpty(
          oldTaskData.confirmedBy
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.confirmedBy)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.amountPaidTransportation !== oldTaskData?.amountPaidTransportation) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.amountPaidTransportation);
        const newVal = this.formatCurrency(newTaskData.amountPaidTransportation);
        const description = `Campo Valor Pago Transporte alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.transportValue !== oldTaskData?.transportValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.transportValue);
        const newVal = this.formatCurrency(newTaskData.transportValue);
        const description = `Campo Valor Transporte alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.refund !== oldTaskData?.refund) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Reembolso alterado de <b>${getValueOrEmpty(oldTaskData.refund)}
          </b> para <b>${getValueOrEmpty(newTaskData.refund)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.complementLogistic !== oldTaskData?.complementLogistic) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Complemento alterado de <b>${getValueOrEmpty(
          oldTaskData.complementLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.complementLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.stateLogistic !== oldTaskData?.stateLogistic) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Estado alterado de <b>${getValueOrEmpty(
          oldTaskData.stateLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.stateLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.cityLogistic !== oldTaskData?.cityLogistic) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Cidade alterado de <b>${getValueOrEmpty(
          oldTaskData.cityLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.cityLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.streetLogistic !== oldTaskData?.streetLogistic) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Rua alterado de <b>${getValueOrEmpty(oldTaskData.streetLogistic)}
          </b> para <b>${getValueOrEmpty(newTaskData.streetLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.postalCodeLogistic !== oldTaskData?.postalCodeLogistic) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo CEP alterado de <b>${getValueOrEmpty(
          oldTaskData.postalCodeLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.postalCodeLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        newTaskData?.responsiblePersonEmailLogistic !== oldTaskData?.responsiblePersonEmailLogistic
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo E-mail Responsável alterado de <b>${getValueOrEmpty(
          oldTaskData.responsiblePersonEmailLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.responsiblePersonEmailLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        newTaskData?.responsiblePersonNameLogistic !== oldTaskData?.responsiblePersonNameLogistic
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Nome Responsável alterado de <b>${getValueOrEmpty(
          oldTaskData.responsiblePersonNameLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.responsiblePersonNameLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        newTaskData?.responsiblePersonPhoneLogistic !== oldTaskData?.responsiblePersonPhoneLogistic
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Telefone Responsável alterado de <b>${getValueOrEmpty(
          oldTaskData.responsiblePersonPhoneLogistic
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.responsiblePersonPhoneLogistic)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.referencePoint !== oldTaskData?.referencePoint) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Ponto de Referência alterado de <b>${getValueOrEmpty(
          oldTaskData.referencePoint
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.referencePoint)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.transportStopAddress !== oldTaskData?.transportStopAddress) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Endereço de Parada do Transporte alterado de <b>${getValueOrEmpty(
          oldTaskData.transportStopAddress
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.transportStopAddress)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.hasDocumentation !== oldTaskData?.hasDocumentation) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Documentação Enviada alterado de <b>${this.translateFlag(
          getValueOrEmpty(oldTaskData.hasDocumentation)
        )}
          </b> para <b>${this.translateFlag(getValueOrEmpty(newTaskData.hasDocumentation))}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.acceptSharedTraining !== oldTaskData?.acceptSharedTraining) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Aceita Encaixe alterado de <b>${this.translateFlag(
          getValueOrEmpty(oldTaskData.acceptSharedTraining)
        )}
          </b> para <b>${this.translateFlag(
            getValueOrEmpty(newTaskData.acceptSharedTraining)
          )}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.attendanceListChecked !== oldTaskData?.attendanceListChecked) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Lista de Presença Verificada alterado de <b>${this.translateFlag(
          getValueOrEmpty(oldTaskData.attendanceListChecked)
        )}
          </b> para <b>${this.translateFlag(
            getValueOrEmpty(newTaskData.attendanceListChecked)
          )}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.commercialNotes !== oldTaskData?.commercialNotes) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Observação Comercial alterado de <b>${getValueOrEmpty(
          oldTaskData.commercialNotes
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.commercialNotes)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.logisticsNotes !== oldTaskData?.logisticsNotes) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Observação Logística alterado de <b>${getValueOrEmpty(
          oldTaskData.logisticsNotes
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.logisticsNotes)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.ctNotes !== oldTaskData?.ctNotes) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Observação CT alterado de <b>${getValueOrEmpty(
          oldTaskData.ctNotes
        )}
          </b> para <b>${getValueOrEmpty(newTaskData.ctNotes)}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        moment(new Date(newTaskData?.boardingHour)).format('HH:mm') !==
        moment(new Date(oldTaskData?.boardingHour)).format('HH:mm')
      ) {
        const oldBoardingHour = oldTaskData?.boardingHour
          ? moment(new Date(oldTaskData?.boardingHour)).format('HH:mm')
          : '"vazio"';
        const newBoardingHour = newTaskData?.boardingHour
          ? moment(new Date(newTaskData?.boardingHour)).format('HH:mm')
          : '"vazio"';

        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Horário de Embarque alterado de <b>${oldBoardingHour}</b> para <b>${newBoardingHour}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (
        newTaskData?.numberResponsibleConfirmation !== oldTaskData?.numberResponsibleConfirmation
      ) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Telefone Responsável Confirmação alterado de <b>${getValueOrEmpty(
          oldTaskData.numberResponsibleConfirmation
        )}</b> para <b>${newTaskData.numberResponsibleConfirmation}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      // Novos campos Financeiro
      if (newTaskData?.invoiceIssuer?.name !== oldTaskData?.invoiceIssuer?.name) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Emissor Nota Fiscal alterado de <b>${getValueOrEmpty(
          oldTaskData.invoiceIssuer?.name
        )}</b> para <b>${newTaskData.invoiceIssuer?.name}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }
      if (newTaskData?.invoiceNumber !== oldTaskData?.invoiceNumber) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const description = `Campo Número Nota Fiscal alterado de <b>${getValueOrEmpty(
          oldTaskData.invoiceNumber
        )}</b> para <b>${newTaskData.invoiceNumber}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }
      if (newTaskData?.totalInvoiceValue !== oldTaskData?.totalInvoiceValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.totalInvoiceValue);
        const newVal = this.formatCurrency(newTaskData.totalInvoiceValue);
        const description = `Campo Valor Nota Fiscal Total alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }

      if (newTaskData?.partialInvoiceValue !== oldTaskData?.partialInvoiceValue) {
        const event = 'FieldChanged';
        const title = 'Alteração de Campo';
        const oldVal = this.formatCurrency(oldTaskData.partialInvoiceValue);
        const newVal = this.formatCurrency(newTaskData.partialInvoiceValue);
        const description = `Campo Valor Nota Fiscal Parcial alterado de <b>${oldVal}</b> para <b>${newVal}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 2);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar as Atividades!');
    }
  }

  static async addTaskCommentDriver(newTaskData, oldTaskData, driverOptions) {
    try {
      const oldList = oldTaskData.driverList?.map(({ userId }) => userId);
      const newList = newTaskData.driverList?.map(({ userId }) => userId);
      const removedIds = [];
      const newIds = [];

      // Monta lista de Motoristas Removidos
      oldList?.forEach((newId) => {
        const item = newList?.find((oldId) => oldId === newId);

        if (!item) {
          removedIds.push(newId);
        }
      });

      // Monta lista de Novos Motoristas
      newList?.forEach((newId) => {
        const item = oldList?.find((oldId) => oldId === newId);

        if (!item) {
          newIds.push(newId);
        }
      });

      // Gera Atividade para Novos Motoristas
      for (let index = 0; index < removedIds?.length; index += 1) {
        const item = driverOptions?.find(({ id }) => id === removedIds[index]);

        const event = 'DriverRemoved';
        const title = 'Remoção de Motorista';
        const description = `Motorista removido: <b>${item.name}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, 'Scheduling', 1);
      }

      // Gera Atividade para Motoristas Removidos
      for (let index = 0; index < newIds?.length; index += 1) {
        const item = driverOptions?.find(({ id }) => id === newIds[index]);

        const event = 'DriverInserted';
        const title = 'Inserção de Motorista';
        const description = `Motorista adicionado: <b>${item.name}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, 'Scheduling', 1);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar as Atividades de Motorista!');
    }
  }

  static async addTaskCommentVehicle(newTaskData, oldTaskData, vehicleOptions) {
    try {
      const oldList = oldTaskData.vehicleList?.map(({ id }) => id);
      const newList = newTaskData.vehicleList?.map(({ id }) => id);
      const removedIds = [];
      const newIds = [];

      // Monta lista de Veículos Removidos
      oldList?.forEach((newId) => {
        const item = newList?.find((oldId) => oldId === newId);

        if (!item) {
          removedIds.push(newId);
        }
      });

      // Monta lista de Novos Veículos
      newList?.forEach((newId) => {
        const item = oldList?.find((oldId) => oldId === newId);

        if (!item) {
          newIds.push(newId);
        }
      });

      // Gera Atividade para Novos Veículos
      for (let index = 0; index < removedIds?.length; index += 1) {
        const item = vehicleOptions?.find(({ id }) => id === removedIds[index]);

        const event = 'VehicleRemoved';
        const title = 'Remoção de Veículo';
        const description = `Veículo removido: <b>${item.label}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, 'Scheduling', 1);
      }

      // Gera Atividade para Veículos Removidos
      for (let index = 0; index < newIds?.length; index += 1) {
        const item = vehicleOptions?.find(({ id }) => id === newIds[index]);

        const event = 'VehicleInserted';
        const title = 'Inserção de Veículo';
        const description = `Veículo adicionado: <b>${item.label}</b>.`;

        await this.fetchTaskComment(newTaskData, title, description, event, 'Scheduling', 1);
      }
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar as Atividades de Veículo!');
    }
  }

  static async addTaskCommentUploadFile(newTaskData, event, eventSource) {
    try {
      let title = '';
      let description = '';

      if (event === 'InitialAttendanceListUploaded') {
        title = 'Envio Lista de Presença Inicial';
        description = `<b>Lista de Presença Inicial</b> enviada.`;
      } else if (event === 'NFTrainingUploaded') {
        title = 'Envio NF Treinamento';
        description = `<b>NF Treinamento</b> enviada.`;
      } else if (event === 'NFTransportationUploaded') {
        title = 'Envio NF Transporte';
        description = `<b>NF Transporte</b> enviada.`;
      } else if (event === 'FinalAttendanceListUploaded') {
        title = 'Envio Lista de Presença Final';
        description = `<b>Lista de Presença Final</b> enviada.`;
      } else if (event === 'InstructorFormUploaded') {
        title = 'Envio Formulário do instrutor';
        description = `<b>Formulário do instrutor</b> enviado.`;
      } else if (event === 'StudentListUploaded') {
        title = 'Envio Lista de Alunos';
        description = `<b>Lista de Alunos</b> enviada.`;
      } else if (event === 'InitialAttendanceListDelete') {
        title = 'Remoção Lista de Presença Inicial';
        description = `<b>Lista de Presença Inicial</b> removida.`;
      } else if (event === 'FinalAttendanceListDelete') {
        title = 'Remoção Lista de Presença Final';
        description = `<b>Lista de Presença Final</b> removida.`;
      } else if (event === 'NFTransportationDelete') {
        title = 'Remoção NF Transporte';
        description = `<b>NF Transporte</b> removida.`;
      } else if (event === 'NFTrainingDelete') {
        title = 'Remoção NF Treinamento';
        description = `<b>NF Treinamento</b> removida.`;
      } else if (event === 'instructorFormDelete') {
        title = 'Remoção Formulário do instrutor';
        description = `<b>Formulário do instrutor</b> removido.`;
      }

      await this.fetchTaskComment(newTaskData, title, description, event, eventSource, 1);
    } catch (error) {
      Utils.logError(error);
      message.error('Oops. Algo deu errado ao gerar as Atividades!');
    }
  }

  static generateTaskCommentsFields(taskComments, fieldsPermissions, filter) {
    let commentsFiltered = [];
    switch (filter) {
      case 'activity':
        commentsFiltered = taskComments.filter(
          ({ activityType }) => activityType === 1 || !activityType
        );
        break;
      case 'history':
        commentsFiltered = taskComments.filter(({ activityType }) => activityType === 2);
        break;
      default:
        commentsFiltered = taskComments;
        break;
    }

    return [
      {
        visible: fieldsPermissions?.Comments.access !== 0 && commentsFiltered?.length > 0,
        template: () => renderToStaticMarkup(<TaskComment comments={commentsFiltered} />),
        label: {
          text: '',
        },
      },
    ];
  }

  static translateFlag(flag) {
    if (flag) {
      return 'Verdadeiro';
    }
    return 'Falso';
  }
}
export default TaskCommentFunctions;
